@import "normalize";
@import "bourbon";
@import "neat";

// Colors
$orange: #EACC78;
$nav-hover: #efefef;
$light-gray: #F0F0F0;
$text_color: #444444;
$text_color_dark: #121214;
$heading_color: $orange;
$link_color: $orange;

// Breakpoints
$mobile: new-breakpoint(max-width 500px 4);
$tablet: new-breakpoint(max-width 900px 9);

@import "bxslider";

body {
  padding: 0;
  margin: 0;
  background-color: #fff;
  font-family: 'Open Sans', sans-serif;
  line-height: 2;
  color: $text-color;
}

.container {
  @include outer-container();
}

.container__inner {
  @include pad(0 40px);
}

.sidebar {
  @include direction-context(right-to-left){
    @include span-columns(4 of 12);
  };

  @include media($tablet){
    //padding-right: 24px;
    //padding-left: 24px;
    @include span-columns(9 of 9);
    padding-bottom: 50px;
  }

  color: $text_color;
}

.sidebar__inner {
  margin-top: 20px;
  padding-right: 50px;
  padding-bottom: 50px;
  margin-bottom: 50px;

  @include media($tablet){
    padding-right: 0px;
  }
}

.home-page-content-logo {
  @include retina-image('/assets/img/layout/vertical_logo', 232px 166px);
  overflow: hidden;
  display: block;
  height: 166px;
  width: 232px;
  text-indent: 100%;
  margin-top: 24px;
  white-space: nowrap;
  overflow: hidden;
  text-indent: -999em;
  padding: 0;
}

.primary {
  @include direction-context(right-to-left){
    @include span-columns(8 of 12);
  };

  @include media($tablet){
    @include fill-parent();
    padding-bottom: 20px;
    //padding-right: 24px;
    //padding-left: 24px;
  }

  @include omega();
  color: $text_color;
  padding: 0 0 80px;

  > h1 {
    margin-top: 0;
    margin-bottom: 0;
  }
}

// ------------------------------- //
// Standard Elements
// ------------------------------- //

a:link, a:visited {
  color: $link_color;
  text-decoration: none;
}

a:hover, a:active {
  color: darken($link_color, 25%);
}

h1, h2, h3, h4, h5, h6 {
  font-family: 'Bitter', serif;
  color: $heading_color;
}

p.lead {
  font-size: 24px;
}
// ------------------------------- //
// Header
// ------------------------------- //

#header__wrapper {
  border-bottom: 1px solid $orange;
}
.header {
  @include outer-container();
  padding: 30px 0;
  text-align: center;
}

.header__inner {
  @include pad(0 50px);
}

.header__logo {
  float: left;
  @include retina-image('/assets/img/layout/logo', 300px 85px);
  //background: transparent url("/assets/img/layout/logo.png") no-repeat center top scroll;
  overflow: hidden;
  display: block;
  height: 85px;
  width: 300px;
  text-indent: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-indent: -999em;
  padding: 0;

  @include media($tablet) {
    @include retina-image('/assets/img/layout/mobile_logo', 226px 64px);
    float: none;
    margin: 0 auto;
    height: 64px;
    width: 226px;
  }
}


// ------------------------------- //
// Navigation
// ------------------------------- //

.main-nav {
  padding: 0;
  margin: 0;
  float: right;
  position: relative;
  top: 24px;
  font-family: 'Bitter', serif;
  @include media($tablet)
  {
    display: none;
  }
}

.main-nav__item {
  display: inline;
  list-style-type: none;
  margin-left: 12px;
}

.main-nav__link {
  color: $orange !important;
  display: inline-block;
  padding: 2px 12px;
  font-size: 14px;
  border-radius: 15px;
}

.main-nav__link--active {
  background-color: $orange;
  color: #fff !important;
  cursor: default;
}

.main-nav__link:hover {
  background-color: $orange;
  color: #fff !important;
}

// ------------------------------- //
// Page Footer
// ------------------------------- //

.footer__wrapper {
  background-color: $orange;
}

.footer__inner {
  @include pad(0 50px);
  @include media($mobile){
    padding: 0;
  }
}

.footer {
  @include outer-container();
  padding: 50px 0;
  text-align: center;

  .footer-nav {
    padding: 0;
    margin: 0;
    float: right;
    position: relative;
    top: 0px;
    font-family: 'Bitter', serif;
    @include media($tablet)
    {
      display: none;
    }
  }

  .footer-nav__item {
    display: inline;
    list-style-type: none;
    margin-left: 12px;
  }

  .footer-nav__link {
    color: #fff !important;
    display: inline-block;
    padding: 2px 12px;
    font-size: 14px;
    border-radius: 15px;
  }

  .footer-nav__link--active {
    background-color: darken($orange, 10%);
    cursor: default;
  }

  .footer-nav__link:hover {
    background-color: darken($orange, 10%);
  }

}

// ------------------------------- //
// Mobile Nav
// ------------------------------- //
//#mobile-nav-shortcut:before { content: '\2630'; }
#mobile-nav-shortcut {
  display: none;
  @include media($tablet){
    display: block;
    margin-top: 12px;
    padding-left: 3px;
    text-transform: uppercase;
    font-size: 12px;
  }
}

#mobile-nav {
  display: none;
  font-family: 'Bitter', serif;

  @include media($tablet){
    display: block !important;
  }

  h3 {
    color: white;
  }
  
  .mobile-nav-list {
    margin: 0;
    padding: 0;

    .mobile-nav__item {
      display: block;
      margin: 0;
      .mobile-nav__link {
        padding: 12px 0;
        display: block;
        color: white;
        font-size: 18px;
        &:hover,
        &.mobile-nav__link--active{
          background-color: darken($orange, 10%);
        }
      }
    }
  }
}

// ------------------------------- //
// Hero
// ------------------------------- //
.home .hero__wrapper {
  background: transparent url("/assets/img/layout/background.jpg") no-repeat scroll center top;
  -moz-background-size: cover;
  background-size: cover;
}

.collection .hero__wrapper {
  background-color: #292929;
  margin: 0;
  padding: 0;
}

.collection-carousel__inner {
  position: relative;
  text-align: center;
  height: 220px;
  overflow: hidden;
}
.collection-carousel__inner .collection-carousel__title {
  position: absolute;
  width: 100%;
  top: 50px;
  text-align: center;
  z-index: 50;

  @include media($tablet)
  {
    padding: 0 24px;
    top: 32px;
  }
  
  h1 {
    font-weight: normal;
    font-size: 50px;
    line-height: 50px;
    letter-spacing: 2px;
    text-shadow: rgba(0,0,0,0.2) 0px 2px 4px;

    @include media($tablet)
    {
      font-size: 36px;
    }
  }
}

// ------------------------------- //
// Tags
// ------------------------------- //
ul.tags {
  padding: 0;
  margin: 0;
  li {
    list-style: none;
    display: inline-block;
    background-color: $light-gray;
    padding: 2px 15px;
    font-size: 11px;
    margin-bottom: 8px;
    font-weight: bold;
    text-transform: lowercase;
    -webkit-border-radius: 100px;
    -moz-border-radius: 100px;
    border-radius: 100px;
    color: lighten($text-color, 30%);
  }
}

// ------------------------------- //
// Product Details Page
// ------------------------------- //

.product-details__wrapper {
  @include clearfix;

  padding-top: 100px;
  padding-bottom: 100px;

  .product-details__gallery-wrapper {
    text-align: center;

    background: transparent url("/assets/img/layout/custom_loader.gif") center center scroll no-repeat;
    @include span-columns(5 of 12);

    @include media($mobile) {
      @include fill-parent();
    }

    .product-details__gallery {
      margin: 0;
      list-style: none;
      padding: 0;
    }
  }

  .product-details__text {
    @include span-columns(6 of 12);
    @include shift(1);
    margin-top: -40px;

    @include media($mobile){
      @include fill-parent();
      @include shift(0);
      margin-top: 0;
    }
  }

  .product-details__tags,
  .product-details__finishes {
    margin-bottom: 36px;
    h3 {
      margin-bottom: 6px;
    }
  }
}

.finishes {
  margin: 0;
  padding: 0;
  @include row();

  list-style: none;

  .finish {

    @include span-columns(3 of 12);
    @include omega(4n);

    @include media($mobile){
      @include span-columns(6 of 12);
      @include omega(2n);
    }

    text-align: center;
    padding: 8px;
    margin-bottom: 12px;
    font-size: 12px;
    background-color: $light-gray;
    font-size: 11px;
    font-weight: bold;
    text-transform: lowercase;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;

    color: lighten($text-color, 30%);

    img {
      width: 100%;
    }
  }

}

// ------------------------------- //
// Page Content
// ------------------------------- //
.about-content {
  @include pad(50px 120px 120px);
  @include media($tablet)
  {
    @include pad(50px 0);
  }
}

.img.float-left {
  float: left;
  margin: 9px 36px 18px 0;
  @include media($mobile)
  {
    float: none;
    margin: 18px 0;
  }

  &.offset {
    margin-left: -80px;
    @include media($tablet)
    {
      margin-left: 0;
    }
  }
}

.img.float-right {
  float: right;
  margin: 9px 0 18px 36px;
  
  @include media($mobile)
  {
    float: none;
    margin: 18px 0;
  }
  
  &.offset {
    margin-right: -80px;
    @include media($tablet)
    {
      margin-right: 0;
    }
  }
}

.img.half {
  width: 50%;
  @include media($mobile){
    width: 100%;
  }
}

.img.third {
  width: 33%;
  @include media($mobile){
    width: 100%;
  }
}


// ------------------------------- //
// Collections
// ------------------------------- //

.collection__description {
  @include pad(50px 120px);
  text-align: center;
  @include media($tablet)
  {
    padding: 24px 0;
  }
}

.collection-thumbs {
  @include row();
  margin-bottom: 60px;
}

.collection-thumb {
  @include span-columns(4 of 12);
  @include omega(3n);
  @include pad(0 12px);
  margin-bottom: 18px;

  @include media($mobile)
  {
    @include fill-parent();
    @include omega(1n);
    padding: 0;
  }

  a {
    display: block;
  }
  a:hover {
  }
  img {
    background-color: #efefef;
    width: 100%;
  }

  .collection-thumb__title {
    font-size: 18px;
    text-align: center;
    margin-top: 0;
  }
}

// ------------------------------- //
// Hero Links
// ------------------------------- //
.hero-links__wrapper {
  background-color: $orange;
  padding: 0px;
}

.home .hero-links__wrapper {
  margin-bottom: 60px;
}

.hero-links {
  text-align: center;
  padding: 0;
  margin: 0;
}

.hero-links__item {
  display: inline-block;
  @include media($mobile){
    display: block;
  }
}

a.hero-links__link:link,
a.hero-links__link:visited,
 {
  position: relative;
  letter-spacing: 1px;
  display: block;
  color: #fff !important;
  padding: 18px 18px;
  font-size: 16px;
  font-family: 'Bitter', sans-serif;
  color: #eee;
  overflow: visible;
  
  @include media($mobile){
    padding: 12px;
  }
}

a.hero-links__link:hover,
a.hero-links__link:active,
a.hero-links__active{
  background-color: darken($orange, 10%);
}

.contact-to-purchase {
  text-align: center;
  margin: 24px auto;
  letter-spacing: 1px;
  color: #fff !important;
  padding: 18px 18px;
  font-size: 16px;
  font-family: 'Bitter', sans-serif;
  color: #eee;
  background-color: $orange;
  overflow: visible;

  &:hover {
    background-color: darken($orange, 10%);
  }
}

.collection {
  a.hero-links__active:after,
  a.hero-links__link:hover:after {
    z-index: 10;
    top: 100%;
    left: 50%;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    border-color: rgba(136, 183, 213, 0);
    border-top-color: darken($orange, 10%);;
    border-width: 20px;
    margin-left: -20px;

    @include media($mobile)
    {
      border: none;
    }
  }
}

// ------------------------------- //
// Carousel
// ------------------------------- //
.carousel__wrapper {
  margin-bottom: 0px;
  padding: 50px 0 50px;
}

.carousel__inner {
  @include outer-container();
  @include media($tablet){
//    padding-right: 24px;
//    padding-left: 24px;
  }
}

.carousel {
  @include span-columns(10 of 12);
  background: transparent url("/assets/img/layout/custom_loader.svg") center center scroll no-repeat;

  @include shift(1);
}

.bxslider {
  margin: 0;
  padding: 0;
  border: 0;
}

// ------------------------------- //
// Forms
// ------------------------------- //

// Contact Form

.contact-form__wrapper {
  @include row();
  @include pad(40px 50px);
  margin-bottom: 60px;
}
.control-label {
  color: $orange;
  font-weight: bold;
  font-side: 14px;
  display: block;
}

.contact-form {
  .contact-form__name,
  .contact-form__email,
  .contact-form__phone{
    @include span-columns(4 of 12);

    @include media($tablet){
      @include fill-parent();
    }
    margin-bottom: 18px;
  }

  .contact-form__phone {
    @include omega();
  }

  .form-control {
    padding: 6px;
    border-radius: 5px;
    border: 1px solid darken(#efefef, 10%);
    height: 36px;
    color: $text_color_dark;
    width: 100%;
    background-color: #efefef;
  }

  .contact-form__username {
    display: none;
  }


  .contact-form__message .form-control {
    height: 200px;
    margin-bottom: 18px;
  }
  .contact-form__submit .form-control {
    background-color: $orange;
    border: none;
    border-radius: 5px;
    height: 36px;
    padding: 6px 12px;
    width: 200px;
    float: right;
    color: $text_color;

    @include media($tablet){
      width: 100%;
      float: none;
    }
  }
}

.contact-form__errors {
  display: block;
  background-color: $orange;
  padding: 3px 12px;
  border-radius: 5px;
  margin-bottom: 18px;
}

.contact-form__feedback {
  @extend .contact-form__errors;
  background-color: $orange;
  color: $text_color_dark;
}